@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400..700&display=swap');

body {
    font-family: 'El Messiri', sans-serif;
background-color: #0D0D0D;
padding: 0;
}
html{
  scroll-behavior: smooth;
}
:root {
  --color-base: #ffffff;
  --color-main: #BB763F;
  --color-primary: #0D0D0D;
  --color-secondery: #171717;
  --color-cards: #1C1C1C;
  --color-spanbg: #000000;

}
@tailwind base;
@tailwind components;
@tailwind utilities;
